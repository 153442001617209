import * as React from 'react'
import LogoLink from './LogoLink'
import './styles.scss'

const Header = ({ category }) => {
    return (
        <header id="top" className="c-header">
            <div className="c-header__top">
                <div className="container">
                    <LogoLink />
                </div>
            </div>

            <div className="c-header__bottom">
                <div className="container">
                    <div className="c-header__bottom-inner">
                        {/* <div className="c-header__subtitle">{category ? (category) : 'Life Insurance'}</div> */}
                        {/* <div className="c-header__contact">
                            <span className="c-header__tel">0333 880 3030</span>
                            <span className="c-header__hours">Mon-Fri - 9:30am to 6:30pm</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
