import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const LogoLink = () => {
    return (
        <a className="c-header__logo-link" href="/">
            <StaticImage
                src="../../../images/ctc-logo-colour.png"
                width={180}
                alt="Compare the Quote"
            />
        </a>
    )
}

export default LogoLink
